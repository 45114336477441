import * as React from "react";
import Socials from '../../data/links.json'

const Footer = () => {
    return (
        <footer class="page-footer pb-5">
            <div class="container">
                <div class="text-center copyrights">©2022 We Are In Plastic</div>
                <ul class="social text-center">
                    {Socials.links.map(link => (
                    <li className="social-item mr-3">
                      <a href={link.url}> 
                        <i className={link.icon}></i> 
                      </a>
                  </li>
                ))} 
                </ul>
                
            </div>
        </footer>
    )
}

export default Footer;